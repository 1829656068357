import styled from 'styled-components'

export const FullScreen = styled.div`
    width: 100%;
    padding: 40px 20px 40px;
    @media (max-width: 900px) {
        h2 {
            font-size: 36px;
            line-height: 45px;
            span {
                padding-right: 6px;
            }
        }
    }

    ${p =>
        p.theme &&
        `
        background-color: ${p.theme.background};
        color: ${p.theme.text};
        div {
            background-color: ${p.theme.tileBackground}
        }
        h1, h2, h3, li, a, strong {
            color: ${p.theme.text};
        }
    `}
`
